import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				О услуга | Premier AutoWorks
			</title>
			<meta name={"description"} content={"Где каждая деталь имеет значение"} />
			<meta property={"og:title"} content={"О услуга | Premier AutoWorks"} />
			<meta property={"og:description"} content={"Где каждая деталь имеет значение"} />
			<meta property={"og:image"} content={"https://aurafelysium.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://aurafelysium.com/img/10364326.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://aurafelysium.com/img/10364326.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://aurafelysium.com/img/10364326.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://aurafelysium.com/img/10364326.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://aurafelysium.com/img/10364326.png"} />
			<meta name={"msapplication-TileImage"} content={"https://aurafelysium.com/img/10364326.png"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://aurafelysium.com/img/3.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="800px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h1" font="--headline2">
				Опыт обслуживания в Premier
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
				В Premier AutoWorks мы понимаем, насколько важен надежный и эффективный автомобиль. Наш комплекс услуг призван удовлетворить все ваши автомобильные потребности и обеспечить наилучшую работу вашего автомобиля. Наши опытные техники способны справиться с любой задачей - от планового технического обслуживания до сложного ремонта.
				</Text>
			</Box>
		</Section>
		<Section background="#ffffff" padding="90px 0 110px 0" quarkly-title="Team-12">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				flex-direction="column"
				lg-width="100%"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="46px 24px"
				sm-grid-template-columns="1fr"
				sm-grid-template-rows="auto"
				sm-grid-gap="36px 0"
				margin="50px 0px 0px 0px"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://aurafelysium.com/img/4.jpg"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
						Текущее обслуживание
						</Text>
						<Text margin="8px 0px 0px 0px" color="--greyD2" font="300 18px/140% --fontFamily-sansHelvetica">
						Замена масла: Обеспечьте бесперебойную работу двигателя, заменив высококачественное масло и фильтр.
						<br/><br/>
Шиномонтаж: От замены до балансировки - наши шиномонтажные услуги помогут продлить срок службы ваших шин и повысить безопасность.
						<br/><br/>
Проверка тормозов: Регулярные проверки и техническое обслуживание, чтобы ваша тормозная система оставалась отзывчивой и надежной.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://aurafelysium.com/img/5.jpg"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
						Услуги по ремонту
						</Text>
						<Text margin="8px 0px 0px 0px" color="--greyD2" font="300 18px/140% --fontFamily-sansHelvetica">
						Ремонт трансмиссии: Экспертный ремонт и обслуживание автоматических и механических коробок передач.
						<br/><br/>
Работа с подвеской: Повысьте комфорт езды и управляемость с помощью наших услуг по ремонту подвески.
						<br/><br/>
Кондиционеры и отопление: Поддерживайте климат-контроль в вашем автомобиле круглый год с помощью наших услуг HVAC.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://aurafelysium.com/img/6.jpg"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
						Индивидуальные решения
						</Text>
						<Text margin="8px 0px 0px 0px" color="--greyD2" font="300 18px/140% --fontFamily-sansHelvetica">
						Модернизация: Повысьте производительность вашего автомобиля с помощью модернизированных деталей и тюнинга.
						<br/><br/>
Кузовные работы: От мелких вмятин до капитального ремонта - в нашем кузовном цехе ваш автомобиль будет выглядеть наилучшим образом.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://aurafelysium.com/img/7.jpg"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
						Расширенная диагностика
						</Text>
						<Text margin="8px 0px 0px 0px" color="--greyD2" font="300 18px/140% --fontFamily-sansHelvetica">
						Диагностика двигателя: Использование новейших технологий для быстрого выявления проблем с двигателем и предотвращения их возникновения в будущем.
						<br/><br/>
Проверка электрических систем: Комплексные проверки для обеспечения правильной работы всех электрических компонентов.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="#dddddd"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					width="80%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Узнайте больше в Premier AutoWorks
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
					Этот список - лишь краткий обзор того, что мы предлагаем. Чтобы полностью изучить спектр услуг и специализаций, которые предоставляет Premier AutoWorks, мы рекомендуем вам посетить наш центр или связаться с нами. Наша команда готова предоставить подробную информацию и помочь вам понять, как мы можем удовлетворить ваши конкретные автомобильные потребности.
					</Text>
				</Box>
				<Link
					href="/contacts"
					color="#000000"
					padding="11px 45px 12px 45px"
					background="--color-primary"
					margin="50px 0px 0px 0px"
					display="inline-block"
					border-radius="8px"
				>
					Контакты
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});